<template>
<div>
    <h2>
        <slot></slot>
        </h2>
</div>
</template>

<style scoped>
h2 {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
    font-family: 'Sriracha', cursive;
}
</style>
